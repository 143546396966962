import { createRef, useImperativeHandle, useState } from "react";

export const CustomToolTipRef = createRef();

export function CustomToolTip() {
  const [show, setShow] = useState(false);
  const [description, setDescription] = useState('');
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  
  useImperativeHandle(CustomToolTipRef, () => {
    return {
      showToolTip: (description, centerY, centerX) => {
        setDescription((prev) => {
          if(prev !== description && (!show && (description && description.length > 0))){
            setShow(description && description.length > 0);
            setTop(centerY);
            setLeft(centerX);
            return description;
          }
          return prev;

        });
      },
      hideToolTip: () => {
        setShow(() => {
          setDescription('');
          return false;
        });
      }
    };
  }, []);

  const calculateLength = () => {
    let lengths = description.split('<br />').map((x) => x.length);

    return Math.max(...lengths);
  }

  return (
    <div 
      style={{ 
        display: show ? 'block' : 'none',
        position: 'absolute', 
        top: `calc(${top}px - 2em - 16px)`, 
        left: `calc(${left}px + ${calculateLength() / -4}em - 16px)`,
        zIndex: 999999999,
        borderRadius: 5,
        backgroundColor: 'black',
        textAlign: 'center',
        padding: 8,
        pointerEvents: 'none'
      }}
      dangerouslySetInnerHTML={{
        __html: description
      }}
    >
    </div>
  );
}

export function TooltipContainer({
  description, 
  children, 
  followMouse = false, 
  enableTooltip = true, 
  Tag = 'span',
  style = {},
  inherit = true,
  onClick
}) {
  return (
        <Tag 
          style={{all: inherit ? 'inherit' : undefined, ...style}}
          onMouseOver = {(e) => {
            if(enableTooltip)
              CustomToolTipRef.current.showToolTip(description, e.nativeEvent.clientY - (followMouse ? 0 : e.nativeEvent.offsetY), e.clientX - (followMouse ? 0 : e.nativeEvent.offsetX)); 
            }
          }
          onMouseLeave = {() => {
            if(enableTooltip)
              CustomToolTipRef.current.hideToolTip();
            }
          }
          onClick={onClick}
        >
          {children}
        </Tag>
      
  )
}
