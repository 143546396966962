import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss";
import "prismjs";
import "prismjs/components/prism-jsx.min";
import "prismjs/themes/prism-tomorrow.css";
import { Suspense, lazy, useImperativeHandle } from "react";
import ReactDOM from "react-dom";
import { Toaster } from "react-hot-toast";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/components/ripple-button";
import Spinner from "./@core/components/spinner/Fallback-spinner";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";
import themeConfig from "./configs/themeConfig";
import { store } from "./redux/store";
import { ThemeContext } from "./utility/context/ThemeColors";
import React from "react";
import { CustomToolTip } from "./components/CustomTooltip";

const LazyApp = lazy(() => import("./App"));

if (!Array.prototype.first)
{
  Array.prototype.first = function(){
      return this[0];
  };
};

if (!Array.prototype.last)
{
  Array.prototype.last = function(){
      return this[this.length - 1];
  };
};

if (!Array.prototype.update)
{
  Array.prototype.update = function(condition, update){
      const index = this.findIndex(condition);
      
      if(index != -1)
        this[index] = update(this[index]);

      return this;
  };
};



ReactDOM.render(
  <BrowserRouter>
    <CustomToolTip />
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <ThemeContext>
          <LazyApp />
          <Toaster
            position={themeConfig.layout.toastPosition}
            toastOptions={{ className: "react-hot-toast" }}
          />
        </ThemeContext>
      </Suspense>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
